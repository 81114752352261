import React from "react";
import './Home.css';
import logo from "./logo.svg";

export const Home: React.FC = () => {
  return (
    <div className="Home">
      <header className="Home-header">
        <img src={logo} className="Home-logo" alt="logo"/>
        <p>
          payments-mock-client
        </p>
      </header>
    </div>
  );
};
