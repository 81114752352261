import React from "react";
import { useLocation } from "react-router-dom";
import './Redirect.css';

export const Redirect: React.FC = () => {
  const { search, hash } = useLocation();
  return (
    <div className="Redirect">
      <p id="query">{search}</p>
      <p id="fragment">{hash}</p>
    </div>
  );
};
